<template>
  <Input
    v-model="searchValue"
    :v="{ value: searchValue }"
    label="Search"
    :value="searchValue"
    end-input-icon="search"
    @keyup.enter="handleInput"
    @icon-clicked="handleInput"
  />
</template>

<script>
import Input from '@/app/components/base/Input';
import { useQuery } from '@/app/use/router';
import { ref } from 'vue';

export default {
  name: 'TableSearch',
  components: { Input },
  setup(props, context) {
    const { search, update } = useQuery(context);
    const searchValue = ref(search.value);

    const handleInput = () => {
      if (searchValue.value.length >= 5) {
        update({
          search: searchValue.value,
        });
      }
    };

    return { searchValue, handleInput };
  },
};
</script>

<style scoped></style>
