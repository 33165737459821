<template>
  <div class="data-table-navigation">
    <slot name="table-search"
      ><div><TableSearch /></div
    ></slot>

    <slot name="table-columns-filter"
      ><div><TableColumnsFilter /></div
    ></slot>
  </div>
  <p class="total-items-paragraph">Total items: {{ totalItems }}</p>
</template>

<script>
import { ref } from 'vue';
import TableSearch from '@/app/components/base/TableSearch';
import TableColumnsFilter from '@/app/components/base/TableColumnsFilter';

export const totalItems = ref(0);
export default {
  name: 'TableNavigation',
  components: { TableColumnsFilter, TableSearch },
  setup() {
    return {
      totalItems,
    };
  },
};
</script>

<style scoped lang="scss">
.data-table-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -1rem 0;
}

.total-items-paragraph {
  text-align: left;
  font-weight: 700;
}

@media (max-width: 767px) {
  .data-table-navigation {
    display: block;
  }
}
</style>
