<template>
  <div class="container-fluid align-left">
    <div class="top-container-wrapper">
      <p class="list-title">Subscriptions</p>
    </div>
    <div>
      <DataTable
        :columns="columns"
        :fetch-function="fetchSubscriptions"
        :route-name="'SubscriptionsSearch'"
      />
    </div>
  </div>
</template>

<script>
import DataTable from '@/app/components/base/DataTable';
import { fetchSubscriptions } from '@/app/api/subscriptions';
import { useContextMenu } from '@/app/use/contextMenu';
import { redirectToRoute } from '@/app/router';
import { h } from 'vue';
import StatusIcon from '@/app/components/base/StatusIcon';
import Icon from '@/app/components/base/Icon';

const columns = [
  {
    title: 'Status',
    key: 'status',
    sorter: () => {},
    render(row) {
      let statusStyle;
      switch (row.status) {
        case 'ACTIVE':
          statusStyle = 'active';
          break;
        case 'INACTIVE':
          statusStyle = 'inactive';
          break;
        case 'PAUSED':
          statusStyle = 'paused';
          break;
        default:
          statusStyle = 'preactive';
      }
      return h(StatusIcon, {
        status: statusStyle,
      });
    },
    width: 80,
    isVisible: true,
  },
  {
    title: 'Id',
    key: 'id',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'UUID',
    key: 'uuid',
    isVisible: false,
  },
  {
    title: 'Email',
    key: 'user.email',
    render(row) {
      return row.email;
    },
    isVisible: true,
  },
  {
    title: 'Card',
    key: 'user.card.mash',
    render(row) {
      return row.card;
    },
    isVisible: true,
  },
  {
    title: 'Offer',
    key: 'offer.name',
    render(row) {
      return row.offer;
    },
    isVisible: true,
  },
  {
    title: 'Domain',
    key: 'mid.domain.name',
    render(row) {
      return row.domain;
    },
    isVisible: true,
  },
  {
    title: '3DS',
    key: 'mid.threeDS',
    render(row) {
      return h(Icon, {
        name: row.threeDS ? '3d_rotation' : '',
      });
    },
    width: 65,
    isVisible: true,
  },
  {
    title: 'Country',
    key: 'offer.country.name',
    render(row) {
      return row.country;
    },
    isVisible: true,
  },
  {
    title: 'CreatedAt',
    key: 'createdAt',
    sorter: () => {},
    isVisible: true,
  },
  {
    title: 'StatusChangedAt',
    key: 'statusChangedAt',
    sorter: () => {},
    isVisible: false,
  },
  {
    title: 'StatusSource',
    key: 'statusSource',
    sorter: () => {},
    isVisible: false,
  },
];

const contextMenuItems = [
  {
    label: 'Manage',
    key: item => {
      redirectToRoute({
        name: 'Subscription',
        params: { id: item.value.id },
      });
    },
  },
];

export default {
  name: 'SubscriptionsList',
  components: { DataTable },
  setup() {
    const { setMenuItems } = useContextMenu();
    setMenuItems(contextMenuItems);

    return {
      fetchSubscriptions,
      columns,
      contextMenuItems,
    };
  },
};
</script>

<style scoped lang="scss">
.list-title {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
}

.top-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.btn-text {
  margin-left: 1rem;
}

@media (max-width: 767px) {
  .top-container-wrapper {
    flex-direction: column;
  }
}
</style>
