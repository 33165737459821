import { http } from '@/app/helpers/axios-instance';
import { toPageable, toPagination } from '@/app/helpers/pagination';

export const fetchSubscriptions = ({ search, pagination = {} }) => {
  return http
    .get('/v1/subscriptions', { params: { search, ...toPageable(pagination) } })
    .then(response => toPagination(response.data));
};

export const fetchSubscriptionById = id => {
  return http.get(`/v1/subscriptions/${id}`).then(response => response.data);
};

export const fetchSubscriptionPayments = ({ id, search, pagination = {} }) => {
  return http
    .get(`/v1/subscriptions/${id}/payments`, { params: { search, ...toPageable(pagination) } })
    .then(response => toPagination(response.data));
};

export const cancelSubscription = uuid =>
  http.post(`/v1/subscriptions/${uuid}/cancel?source=CUSTOMER_CARE`).then(response => response.data);

export const blacklistSubscription = uuid =>
  http.post(`/v1/subscriptions/${uuid}/blacklist`).then(response => response.data);

export const pauseSubscription = uuid =>
  http.post(`/v1/subscriptions/${uuid}/pause?source=CUSTOMER_CARE`).then(response => response.data);

export const reactivateSubscription = uuid =>
http.post(`/v1/subscriptions/${uuid}/reactivate?source=CUSTOMER_CARE`).then(response => response.data);
