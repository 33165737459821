<template>
  <n-pagination
    v-model:page="page"
    v-model:page-size="currentPageSize"
    :item-count="totalItems"
    :page-sizes="[10, 20, 50, 100]"
    show-size-picker
    @update:page="handlePageChange"
    @update:page-size="handlePageSizeChange"
  />
</template>

<script>
import { ref } from 'vue';
import { useQuery } from '@/app/use/router';
import { NPagination } from 'naive-ui';
import { totalItems } from '@/app/components/base/TableNavigation';

export default {
  name: 'Pagination',
  components: { NPagination },
  setup() {
    const { update, pagination } = useQuery();
    const currentPageSize = ref(pagination.value.rowsPerPage);
    const page = ref(pagination.value.page);

    const handlePageChange = page => {
      update({
        page: page,
      });
    };

    const handlePageSizeChange = pageSize => {
      currentPageSize.value = pageSize;
      update({
        rowsPerPage: pageSize,
        page: 0,
      });
    };

    return { page, currentPageSize, handlePageChange, handlePageSizeChange, totalItems };
  },
};
</script>

<style scoped></style>
