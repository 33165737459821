<template>
  <div class="input-wrapper" :class="{ focused: focused }" @click="onInputFocus">
    <label>{{ label }}</label>
    <input
      v-bind="$attrs"
      :value="modelValue"
      :disabled="disabled"
      :class="{ error: v.isError }"
      :style="{ width: `${inputWidth}px` }"
      @focus="focused = true"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="!modelValue ? (focused = false) : (focused = true)"
    />

    <Icon :name="endInputIcon" class="input-icon" @click="iconClicked" />

    <transition name="fade">
      <div v-if="v.isError">
        <p v-for="error in v.errorMessages" :key="error" class="error-message">
          {{ error }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import Icon from '@/app/components/base/Icon';

export default {
  components: { Icon },
  props: {
    v: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputWidth: {
      type: String,
      required: false,
      default: 'none',
    },
    endInputIcon: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue', 'icon-clicked'],
  setup(props) {
    const focused = ref(false);

    const onInputFocus = () => {
      focused.value = true;
    };

    if (props.v.value) {
      onInputFocus();
    }

    return { focused, onInputFocus };
  },
  methods: {
    iconClicked() {
      this.$emit('icon-clicked');
    },
  },
};
</script>

<style scoped lang="scss">
.input-wrapper {
  width: 500px;
  max-width: 100%;
  position: relative;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

label {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #999;
  background-color: #fff;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: auto;
}

.focused label {
  transform: translateY(-115%);
  font-size: 0.75em;
}

input {
  position: relative;
  padding: 12px 0;
  width: 100%;
  outline: 0;
  border: 0.5px solid grey;
  text-indent: 10px;
  transition: box-shadow 150ms ease-out;
  border-radius: 3px;

  &:focus {
    box-shadow: 0 0 5pt 0.5pt #d3d3d3;
    border-color: var(--primary-color);
  }

  &.error {
    border-color: var(--error-color);
  }
}

.input-icon {
  position: absolute;
  right: 5px;
  opacity: 0.5;
}

.error-message {
  color: var(--error-color);
  font-size: 13px;
  margin: 2px 0;
}
</style>
