<template>
  <div class="status-circle" :class="status"></div>
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.status-circle {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin: 0 auto;

  &.active {
    background: radial-gradient(at 100% 0, #9be959, #00a474);
  }

  &.preactive {
    background: radial-gradient(at 100% 0%, #bfbfbf, #8a8a8a);
  }

  &.inactive {
    background: radial-gradient(at 100% 0%, #ff0127, #b91b32);
  }

  &.paused {
    background: radial-gradient(at 100% 0%, #e8e656, #ff9d00);
  }
}
</style>
